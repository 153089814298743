/**
 */
var modules = modules || {};
modules.diningEventsWeekViewManager = function () {

    var instantiated,
        venue_url_key_or_id,
        media_url,
        id,
        start_of_day_hour;

    var helpers = {
        shiftCategoryOptions: _.object([
                                        ['BREAKFAST', 'Breakfast'],
                                        ['BRUNCH', 'Brunch'],
                                        ['LUNCH', 'Lunch'],
                                        ['DAY', 'Day'],
                                        ['DINNER', 'Dinner'],
                                        ['LEGACY', 'Night']
                                        ]
        ),

        backgroundOverlayInterval: React.createClass({
            onClick: function (e) {
                // Only create slideout if the date is future date
                var currentTarget = $(e.currentTarget).closest('.fraction'),
                    start_time_parts = modules.weekViewManager.getHourFraction($(e.currentTarget).attr('data-starttime')),
                    end_time_parts = modules.weekViewManager.getHourFraction($(e.currentTarget).attr('data-endtime')),
                    intervalHours = (end_time_parts.hour - start_time_parts.hour) +
                        (end_time_parts.fraction - start_time_parts.fraction) / 4,
                    adjustedIntervalHours = intervalHours < 0 ? intervalHours + 24 : intervalHours;
                // Remove all resizables
                $('.ui-resizable').remove();
                helpers.createResizable(currentTarget, adjustedIntervalHours, undefined, $(e.currentTarget).attr('data-category'));
                var targetOverlay = $(currentTarget).find('.overlay_interval').filter(":not([data-id])");
                onClickOverlay(targetOverlay);
            },

            render: function () {
                var calculatedZIndex = 100 + Number(this.props.index ? this.props.index : 0),
                    calculatedHeight = this.props.height ? this.props.height : (4 * cellSize - 4),
                    isFutureDate = new Date(this.props.day) >= Pmp.Manager.Global._venue_today_date,
                    width = (this.props.maxWidth + this.props.left) >= 100 ? (100 - this.props.left) : this.props.maxWidth,
                    overlayIntervalCss = {
                        position: 'absolute',
                        zIndex: calculatedZIndex,
                        backgroundColor: isFutureDate ? 'rgba(161, 190, 254, 0.2)' : 'rgba(161, 190, 254, 0.1)',
                        right: '1px',
                        height: (calculatedHeight - 1),
                        top: '0px',
                        left: '0%',
                        borderRadius: 3,
                        cursor: isFutureDate ? 'pointer' : undefined,
                        padding: '2px',
                        overflow: 'hidden'
                    };
                return (
                    <div title={this.props.title}
                         className='backgroundoverlay_interval'
                         style={overlayIntervalCss}
                         data-starttime={this.props.startTime}
                         data-endtime={this.props.endTime}
                         data-day={this.props.day}
                         data-category={this.props.category}
                         onClick={isFutureDate ? this.onClick : undefined}
                         data-originalwidth={width}
                         data-id={this.props.id}
                         data-backgroundcolor={'#A1BEFE'}
                         data-zindex={calculatedZIndex}>
                    </div>
                );
            },
        }),

        overlayInterval: React.createClass({
            onMouseOver: function (e) {
                $(e.currentTarget).css({
                    border: '1px solid lightgrey'
                });
            },

            onMouseOut: function (e) {
                $(e.currentTarget).css({
                    border: 1
                });
            },

            shadeColor: function (color, percent) {
                var R = parseInt(color.substring(1, 3), 16),
                    G = parseInt(color.substring(3, 5), 16),
                    B = parseInt(color.substring(5, 7), 16);

                R = parseInt(R * (100 + percent) / 100);
                G = parseInt(G * (100 + percent) / 100);
                B = parseInt(B * (100 + percent) / 100);

                R = (R < 255) ? R : 255;
                G = (G < 255) ? G : 255;
                B = (B < 255) ? B : 255;

                var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
                var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
                var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

                return "#" + RR + GG + BB;
            },

            onMouseClick: function (e) {
                $(e.currentTarget).css({'background-color': '#CEDEFF', zIndex: 250});
            },

            getDisplayBlock: function (category, title, calculatedHeight) {
                var categoryDisplay = helpers.shiftCategoryOptions[category];
                return calculatedHeight >= 20 ?
                    (<div>
                        <div className='title'
                             style={{width: '100%',
                                    fontSize: 9,
                                    textAlign: 'left',
                                    marginTop: '0%',
                                    fontWeight: 'bold',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    height: 10}}>
                            {title}
                        </div>
                        <div className='category'
                             style={{width: '100%',
                                    fontSize: 9,
                                    textAlign: 'left',
                                    marginTop: '0%',
                                    height: 10,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'}}>
                            { categoryDisplay }
                        </div>
                    </div>) :
                    (<div>
                        <div className='category'
                             style={{width: '100%',
                                    fontSize: 9,
                                    textAlign: 'left',
                                    marginTop: '0%',
                                    overflow: 'hidden',
                                    height: 10}}>
                            <span key="1" style={{fontWeight: 'bold'}}>
                                {title}
                            </span>
                            <span key="2">
                                { categoryDisplay }
                            </span>
                        </div>
                    </div>);
            },

            render: function () {
                var colors = ['#BA68C8', '#FDD835', '#FFF176', '#1DE9B6',
                        '#B9F6CA', '#00BCD4', '#80DEEA', '#8C9EFF', '#A1BEFE', '#2C96FF', '#6AB8FF',
                        '#FF5252', '#FF80AB', '#B0BEC5', '#CE93D8', '#EEEEEE'],
                    calculatedZIndex = 200 + Number(this.props.index ? this.props.index : 0),
                    isPast = new Date(this.props.day) < Pmp.Manager.Global._venue_today_date,
                    diffColors = _.difference(colors, _.values(helpers.colorsLookup)),
                    colorsOptions = _.size(diffColors) ? diffColors: colors,
                    calculatedHeight = this.props.height ? this.props.height : (4 * cellSize - 4),
                    selectedColor = helpers.colorsLookup[this.props.id] ?
                        helpers.colorsLookup[this.props.id] :
                        _.sample(colorsOptions),
                    width = (this.props.maxWidth + this.props.left) >= 90 ? (90 - this.props.left) : this.props.maxWidth,
                    overlayIntervalCss = {
                        position: 'absolute',
                        zIndex: calculatedZIndex,
                        backgroundColor: this.props.isOverride ? selectedColor : this.shadeColor(selectedColor, 25),
                        right: '1px',
                        height: (calculatedHeight - 1),
                        top: '0px',
                        width: width + '%',
                        left: (this.props.left ? this.props.left : 0) + '%',
                        padding: '2px',
                        opacity: isPast ? 0.6 : 1,
                        marginBottom: 1,
                        boxShadow: '1px 1px 1px ' + this.shadeColor(selectedColor, -20),
                        cursor: 'pointer',
                        overflow: 'hidden'
                    };
                helpers.colorsLookup[this.props.id] = selectedColor;

                return (
                    <div className='overlay_interval'
                         style={overlayIntervalCss}
                         data-starttime={this.props.startTime}
                         data-endtime={this.props.endTime}
                         data-day={this.props.day}
                         data-originalwidth={width}
                         data-category={this.props.category}
                         data-id={this.props.id}
                         data-backgroundcolor={this.props.isOverride ? selectedColor : this.shadeColor(selectedColor, 25)}
                         data-zindex={calculatedZIndex}
                         onMouseOver={this.onMouseOver}
                         onResize={this.onMouseOver}
                         onMouseOut={this.onMouseOut}
                         onClick={this.onMouseClick}>
                        {this.getDisplayBlock(this.props.category, this.props.title, calculatedHeight)}
                    </div>
                );
            },
        }),

        getNeighboringOverlayIntervals: function (currTarget, direction_down) {
            var currColumnNum = $(currTarget).prevAll('td').size(),
                effectiveRows = [];
            if (direction_down) {
                effectiveRows = $(currTarget).closest('tr').nextAll('tr');
            } else {
                effectiveRows = $(currTarget).closest('tr').prevAll('tr');
            }
            return _.flatten(_.map(effectiveRows, function (row) {
                var currRow = $(row).find('td')[currColumnNum];
                return $(currRow).find('.overlay_interval');
            }));
        },

        // Specific to check if a target div gets overlapping any of the adjacent overlay
        doesOverlap: function (target, adjacentOverlay) {
            if (_.isEmpty(adjacentOverlay)) {
                return false;
            }
            var divTop = $(target).offset().top,
                adjacentOverlayTop = $(adjacentOverlay).offset().top,
                adjacentOverlayHeight = adjacentOverlay.offsetHeight;
            return (divTop > adjacentOverlayTop) &&
                (divTop < (adjacentOverlayTop + adjacentOverlayHeight));
        },

        resetResizableOverlays: function (startTime, endTime) {
            var startFractions = modules.weekViewManager.getHourFraction(startTime),
                endFractions = modules.weekViewManager.getHourFraction(endTime),
                calculatedDifference = (endFractions.hour - startFractions.hour +
                (endFractions.fraction - startFractions.fraction) / 4);

            calculatedDifference = calculatedDifference < 0 ? calculatedDifference + 24 : calculatedDifference
            var resizables = $('#'+helpers.id).find('.ui-resizable');
            $(resizables).each(function(i, overlay) {
                var selector = ["[data-day='", $(overlay).data('day'), "']",
                              "[data-start='", startFractions.hour , "']",
                              "[data-fraction='", startFractions.fraction, "']"].join(''),
                targetNode = _.first($('#'+ helpers.id).find(selector));
                $(overlay).detach().appendTo(targetNode);
                $(overlay).height(calculatedDifference * cellSize * 4 - 6);

                // HACK: Force stop -- there doesn't seem to be any other way
                if (i === (resizables.length - 1)) {
                    var uiResizable = $(overlay).data('uiResizable');
                    uiResizable.options.stop(event, uiResizable.ui())
                }
            });
        },

        onAddOverlay: function (id, d, hours, fraction, total_duration, isChecked) {
            if (isChecked) {
                var selector = ["[data-day='", d, "']",
                        "[data-start='", hours, "']",
                        "[data-fraction='", fraction, "']"].join(''),
                    targetNode = $('#' + id).find(selector),
                    anyResizable  = !!$('#' + id).find('.ui-resizable').size(),
                    anyNeighborResizable = !!$('#' + id).find('.ui-resizable').filter(":not([data-day='" + d + "'])").size();
                if (targetNode.size() && (!anyResizable || anyNeighborResizable)) {
                    helpers.createResizable(targetNode, total_duration);
                } else {
                    console.log('invalid target');
                }
            } else {
                var targetNode = $("[data-day='" + d + "']").filter('.ui-resizable');
                if (targetNode) {
                    React.unmountComponentAtNode(targetNode.parent()[0]);
                }
            }
        },

        createResizable: function (currentTarget, hours, title, category) {
            var totalRows = 25,
                day = $(currentTarget).attr('data-day'),
                startTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction')),
                endTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction'), hours * 4);

            $(currentTarget).append('<div data-id=\'\'/>');
            var x = $(currentTarget).find('[data-id=\'\']');

            React.render(
                <helpers.overlayInterval
                    title={title}
                    index='100'
                    category={category}
                    day={day}
                    maxWidth={90}
                    />,
                x[0]
            );

            var targetOverlay = $(currentTarget).find('.overlay_interval').filter(":not([data-id])");
            $(targetOverlay).resizable({
                handles: 'n, s',
                grid: [0, cellSize],
                axis: 'y',
                minHeight: cellSize,
                maxHeight: totalRows * cellSize * 4 - 6,
                create: function (event, ui) {
                    $(event.target).height((hours * 4 * cellSize) - 4);
                },
                resize: _.throttle(_.bind(function (event, ui) {
                    var currentElement = $(ui.element),
                        currentTarget = $(ui.element).closest('.fraction'),
                        remainingCells = helpers.getNeighboringOverlayIntervals(currentTarget, true),
                        previousCells = helpers.getNeighboringOverlayIntervals(currentTarget, false),
                        nextInterval = _.first(_.filter(_.map(remainingCells, function (x, k) {
                            return _.first($(x).find('.ui-resizable'));
                        }))),
                        prevInterval = _.first(_.filter(_.map(previousCells, function (x, k) {
                            return _.first($(x).find('.ui-resizable'));
                        })));

                    // Restrict northward movement
                    if (prevInterval &&
                        $(currentElement).offset().top < $(prevInterval).offset().top + $(prevInterval).outerHeight()) {
                        ui.position.top = ui.originalPosition.top;
                    }
                    // Restrict southward movement
                    if (nextInterval &&
                        $(currentElement).offset().top + $(currentElement).outerHeight() > $(nextInterval).offset().top) {
                        ui.position.top = ui.originalPosition.top;
                    }

                }, this), 100),

                stop: function (event, ui) {
                    var currentElement = $(ui.element),
                        currentTarget = $(ui.element).closest('.fraction'),
                        currentStartHour = $(currentTarget).data('start'),
                        currentStartFraction = $(currentTarget).data('fraction'),
                        fractionFromStart = Math.round($(currentElement).position().top / cellSize),
                        totalFractions = Math.round(currentElement.outerHeight() / cellSize),
                        startTime = modules.weekViewManager.getDisplayInterval(
                            currentStartHour, currentStartFraction, fractionFromStart
                        ),
                        endTime = modules.weekViewManager.getDisplayInterval(
                            currentStartHour, currentStartFraction, totalFractions + fractionFromStart
                        );

                    // HACK: Invoke others
                    $(currentElement).closest('table').find('.ui-resizable').each(
                        function (index, overlay) {
                            helpers.replicateOverlay(overlay, currentElement, startTime, endTime);
                        }
                    );
                }
            });

            var resizableCss = {
                'background-image': 'url(' + helpers.media_url + 'images/icons/icon-drag.png)',
                'background-size': '23px',
                'left': '30%',
                'width': '40%'
            };
            $(currentTarget).find('div').find('.ui-resizable-n').css(_.extend({top: '-2px'}, resizableCss));
            $(currentTarget).find('div').find('.ui-resizable-s').css(_.extend({bottom: '-2px'}, resizableCss));
        }
        ,

        replicateOverlay: function (element, sourceElement, startTime, endTime) {
            var startTimeDisplay = Pmp.Utils.timeWithLocale(startTime),
                endTimeDisplay = Pmp.Utils.timeWithLocale(endTime);
            // Reset the time
            $(element).find('.time').html(startTimeDisplay + ' - ' + endTimeDisplay);
            $(element).height($(sourceElement).height());
            $(element).offset({top: $(sourceElement).offset().top});

            // Update data attributes
            $(element).attr('data-starttime', startTime);
            $(element).attr('data-endtime', endTime);
        }
        ,

        unMountOverlays: function(targetDiv) {
            // This needs to be done because overlays are mounted not as part of jsx render
            $(targetDiv).find('[data-id]').each(function(i, d) { React.unmountComponentAtNode(d); })
        },

        createBackgroundOverlay: function (currentTarget, hours, title, category, id, key, maxWidth, left) {
            var day = $(currentTarget).attr('data-day'),
                startTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction')),
                endTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction'), hours * 4);

            $(currentTarget).append('<div data-id=' + id + '/>');
            var x = $(currentTarget).find('[data-id=' + id.replace('.', '\\.') + ']');
            React.render(
                <helpers.backgroundOverlayInterval
                    key={id}
                    index={key}
                    height={cellSize * hours * 4 - 4}
                    maxWidth={maxWidth}
                    left={left}
                    title={title}
                    category={category}
                    startTime={startTime}
                    endTime={endTime}
                    day={day}
                    id={id}
                    />,
                x[0]
            );
        },

        createOverlay: function (currentTarget, hours, title, category, id, key, maxWidth, left, is_override) {
            var day = $(currentTarget).attr('data-day'),
                startTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction')),
                endTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction'), hours * 4);

            $(currentTarget).append('<div data-id=' + id + '/>');
            var x = $(currentTarget).find('[data-id=' + id.replace('.', '\\.') + ']');
            React.render(
                <helpers.overlayInterval
                    key={id}
                    index={key}
                    height={cellSize * hours * 4 - 4}
                    maxWidth={maxWidth}
                    left={left}
                    isOverride={is_override}
                    title={title}
                    category={category}
                    startTime={startTime}
                    endTime={endTime}
                    day={day}
                    id={id}
                    />,
                x[0]
            );
        }
        ,

        getSelectedDays: function (day_of_week) {
            // Adjust mon -> sun => sun -> sat
            var rotated_day_of_week = [].concat(day_of_week);
            rotated_day_of_week.unshift(rotated_day_of_week.pop());
            var days = ["S", "M", "T", "W", "TH", "F", "SA"];
            return _.map(_.reject(
                _.map(rotated_day_of_week, function (k, i) {
                    return k ? i : undefined;
                }), _.isUndefined), function (i) {
                return days[i];
            });
        }
        ,

        hourIntervalView: React.createClass({

            onClickHandler: function (e) {
                var currentTarget = $(e.currentTarget),
                    target = $(e.target).closest('.overlay_interval'),
                    isTargetOverlay = $(target).size(),
                    selectedDay = $(currentTarget).data('day'),
                    isFutureDate = new Date(selectedDay) >= Pmp.Manager.Global._venue_today_date,
                    doesResizableExist = !!$(this.getDOMNode()).closest('table').find('.ui-resizable').size();
                // If target element is clickable, proceed
                if (isTargetOverlay) {
                    this.props.onClickOverlay(target);
                // Ensure that no resizable already exists
                } else {
                    console.debug('resizable exists already');
                }
            },

            render: function () {
                var isFutureDate = new Date(this.props.day) >= Pmp.Manager.Global._venue_today_date,
                    borderTop = this.props.start == start_of_day_hour && this.props.fraction == '0' ? '1px solid #DADADA' : 0,
                    intervalCss = {
                        width: '14.28%',
                        height: cellSize,
                        padding: 0,
                        position: 'relative',
                        borderTop: borderTop,
                        borderBottom: 0,
                        borderLeft: '1px solid #DADADA',
                        borderRight: 0,
                        backgroundColor: isFutureDate ? '#FAFAFA' : '#EAEAEA',
                    }, fractionCss = {
                        position: 'relative',
                        height: cellSize,
                        width: '100%'
                    }

                var fractions = _.times(4, function (fraction) {
                    var applicableStyle = _.extend({}, fractionCss,
                        fraction == 3 ?
                        {
                            borderBottom: '1px dotted #CACACA',
                            height: cellSize - 1
                        } : (fraction == 0 && this.props.start == start_of_day_hour) ?
                        {
                            borderTop: '1px solid #DADADA'
                        } : {});
                    return (<div className='fraction'
                                 style={applicableStyle}
                                 data-start={this.props.start}
                                 data-end={this.props.end}
                                 onClick={this.onClickHandler}
                                 data-day={this.props.day}
                                 data-fraction={fraction}
                                 key={fraction}
                        />);
                }, this);

                return (<td style={intervalCss}
                            onMouseOver={this.onMouseOver}
                            onMouseOut={this.onMouseOut}
                            data-start={this.props.start}
                            data-end={this.props.end}
                            data-day={this.props.day}>
                    {fractions}
                </td>);
            },
        }),

        LeftMark: React.createClass({
            render: function () {
                var calculatedHeight = -(10 + cellSize);
                var topcellCss = {
                    width: 44,
                    padding: 0,
                    top: calculatedHeight,
                    position: 'relative',
                    fontSize: 10,
                    height: 8,
                }, tdCss = {
                    color: '#C1C1C1',
                    border: 0
                }, bottomCss = {};

                _.extend(bottomCss, topcellCss, {
                    top: 2,
                    height: '5px'
                });
                var startInterval = modules.weekViewManager.getDisplayInterval(this.props.start);
                var endInterval = modules.weekViewManager.getDisplayInterval(this.props.end);
                var startTimeDisplay = Pmp.Utils.timeWithLocale(startInterval);
                var endTimeDisplay = Pmp.Utils.timeWithLocale(endInterval);
                return !this.props.is_last ?
                    (<td style={tdCss}>
                        <div style={topcellCss}>
                            {startTimeDisplay}
                        </div>
                    </td>) :
                    (<td style={tdCss}>
                        <div style={topcellCss}>
                            {startTimeDisplay}
                        </div>
                        <div style={bottomCss}>
                            {endTimeDisplay}
                        </div>
                    </td>)
            },
        }),

        RowView: React.createClass({
            render: function () {
                var intervals = _.map(this.props.days, function (x, k) {
                        return <helpers.hourIntervalView
                            start={this.props.start_interval}
                            isPassedDay={Pmp.Manager.Global._venue_today_date > new Date(x)}
                            end={this.props.end_interval}
                            index={k}
                            key={k}
                            day={x}
                            onClickOverlay={this.props.onClickOverlay}
                            intervals={this.props.intervals}
                            />;
                    }, this),
                    leftMark = (<helpers.LeftMark
                        start={this.props.start_interval}
                        end={this.props.end_interval}
                        index={0}
                        key={0}
                        is_last={this.props.is_last}
                        />);

                return (
                    <tr className='row'>
                        {leftMark}
                        {intervals}
                    </tr>
                );
            }
        }),

        bindCalendarBehavior: function (divElement, selectedDate, callback) {
            var jsDate = $.datepicker.parseDate('mm/dd/yy', selectedDate);
            $(divElement).datepicker({
                dateFormat: 'mm/dd/yy',
                minDate: $.datepicker.parseDate('mm/dd/yy', ''),
                closeText: 'Cancel',
                firstDay: 0,
                parentObj: this,
                showButtonPanel: true,

                beforeShow: function () {
                    $('#ui-datepicker-div').addClass('customize');
                },
                onSelect: function (dateText, selObj) {
                    callback(dateText);
                },
                prependZero: function (num) {
                    return ("0" + num).slice(-2);
                }
            });
            $(divElement).datepicker("setDate", jsDate, true);
        },

        calendarView: React.createClass({
            render: function () {
                return (
                    <input readOnly="true"
                           type="text"
                           name="date"
                           className="date-selector hasDatePicker"
                           style={{
                                backgroundImage: 'url(' + helpers.media_url  + 'images/icons/calendar-negative.png)',
                                backgroundSize: 16,
                                height: 16,
                                backgroundColor: '#A7A7A7',
                                width: 16,
                                float: 'right',
                                margin: 5,
                                color: 'rgba(127, 127, 127, 0)',
                                padding: 0,
                                cursor: 'pointer'
                            }}
                    />
                );
            }
        }),

        controlView: React.createClass({
            getSelectedWeekDisplay: function (dateText) {
                var selectedDate = new Date(dateText),
                    saturday = selectedDate.addDays(-selectedDate.getDay()),
                    sunday = new Date(saturday);
                saturday.addDays(6);

                var sameYear = sunday.getYear() == saturday.getYear(),
                    sameMonth = sunday.getMonth() == saturday.getMonth();
                if (sameMonth) {
                    return [sunday.toLocaleString('default', { month: 'short' }), " ",
                        sunday.getDate(), "-", saturday.getDate(), " ",
                        saturday.getFullYear()].join('')
                } else if (sameYear) {
                    return [sunday.toLocaleString('default', { month: 'short' }), " ", sunday.getDate(), "-",
                        saturday.toLocaleString('default', { month: 'short' }), " ", saturday.getDate(), " ",
                        saturday.getFullYear()].join('')
                } else {
                    return [sunday.toLocaleString('default', { month: 'short' }), " ", sunday.getDate(), " ", sunday.getFullYear(), "-",
                        saturday.toLocaleString('default', { month: 'short' }), " ", saturday.getDate(), " ", saturday.getFullYear()
                    ].join('')
                }
            },

            onThisWeek: function () {
                this.props.onDateUpdate($.datepicker.formatDate('mm/dd/yy', new Date()));
            },

            onBackOneWeek: function () {
                this.updateDateByDays(-7);
            },

            onForwardOneWeek: function () {
                this.updateDateByDays(7);
            },

            isSameWeekAsCurrentWeek() {
                return (new Date(this.props.selectedDate)).getWeek() == (new Date()).getWeek();
            },

            updateDateByDays: function (n) {
                var d = new Date(this.props.selectedDate);
                d.addDays(n);
                this.props.onDateUpdate($.datepicker.formatDate('mm/dd/yy', d));
            },
            render: function () {
                var backgroundColor = this.isSameWeekAsCurrentWeek() ? '#D8D9D9' : 'blue';
                return (
                    <div style={{
                        borderTop: '1px solid lightgrey',
                        borderBottom: '1px solid lightgrey',
                        overflow: 'hidden',
                        color: '#D8D9D9',
                        fontFamily: 'Roboto'
                    }}>
                        <div className="layout"
                             style={{float: 'left',
                                    width: 20,
                                    height: 16,
                                    backgroundSize: '100% 100%',
                                    margin: 7
                                }}
                            >
                        </div>
                        <div className="selected-date"
                             style={{
                                float: 'left',
                                color: '#878787',
                                paddingLeft: 10,
                                fontSize: 14,
                                height: 30,
                                lineHeight: '30px',
                                borderLeft: '1px solid lightgrey'
                             }}
                            >
                            <b>{this.getSelectedWeekDisplay(this.props.selectedDate)}</b>
                        </div>
                        <helpers.calendarView />
                        <div className="selection"
                             onClick={this.onThisWeek}
                             style={{
                                float: 'right',
                                color: backgroundColor,
                                width: 70,
                                height: 30,
                                lineHeight: '30px',
                                fontSize: 11,
                                fontFamily: 'Roboto',
                                marginLeft: 20,
                                cursor: 'pointer',
                                borderRight: '1px solid lightgrey'
                             }}
                            >
                            This week
                        </div>
                        <div className="selection" style={{
                                float: 'right',
                                height: 30,
                                fontSize: 14,
                                color: 'black',
                                cursor: 'pointer',
                                lineHeight: '30px',
                                borderLeft: '1px solid lightgrey',
                                borderRight: '1px solid lightgrey'
                            }}>
                            <div onClick={this.onBackOneWeek}
                                 style={{float: 'left', width: 30, textAlign: 'center', height: 30, lineHeight: '30px'}}>
                                &lt;
                            </div>
                            <div onClick={this.onForwardOneWeek}
                                 style={{float: 'left', width: 30, textAlign: 'center', height: 30, borderLeft: '1px solid lightgrey', lineHeight: '30px'}}>
                                &gt;
                            </div>
                        </div>

                    </div>
                );
            }
        }),


        HeaderView: React.createClass({
            getDateDisplay: function (d) {
                try {
                    var dt = new Date(d);
                    var dateDisplay = Pmp.Utils.isMonthDayDateFormat() ? (dt.getMonth() + 1) + "/" + dt.getDate() : dt.getDate() + "/" + (dt.getMonth() + 1);
                    return dt.toLocaleString('default', { weekday: 'long' }).toUpperCase() + " " + dateDisplay;
                } catch (e) {
                    // Korean formatted date doesn't parse back into new Date(d)
                    return d;
                }
            },

            render: function () {
                var days = (this.props.days || []).sort(function (a, b) {
                        return new Date(a) > new Date(b) ? 1 : -1;
                    }),
                    displayDates = _.map(days, function (d) {
                        return [d, this.getDateDisplay(d)];
                    }, this),
                    headers = _.map(displayDates, function (d, k) {
                        return (
                            <helpers.headerCellMenu key={k} day={d[0]} disp={d[1]} media_url={helpers.media_url}/>
                        );
                    }, this);
                return (<thead>
                <tr>
                    <th style={{border: 0}}></th>
                    {headers}
                </tr>
                </thead>);
            }
        }),

        TableView: React.createClass({
            propTypes: {
                selectedDate: React.PropTypes.string,
                diningEventsByDay: React.PropTypes.array,
                intervals: React.PropTypes.array,
                days: React.PropTypes.array,
            },

            getDefaultProps: function () {
                return {
                    intervals: [],
                    diningEventsByDay: [],
                    days: []
                };
            },

            componentWillMount: function () {
                var date = $.datepicker.formatDate('mm/dd/yy', new Date());
                this.props.intervals = helpers.generateIntervals(start_of_day_hour);
                this.props.days = helpers.generateDefaultDays(date);
                this.onDateUpdate(date);
                this.forceUpdate();
            },

            getInitialState: function () {
                var date = this.props.date ? this.props.date : $.datepicker.formatDate('mm/dd/yy', new Date());
                return {
                    selectedDate: date
                };
            },

            postRenderHook: function (headDiv) {
                $(headDiv).find('.overlay_interval').remove();
                helpers.unMountOverlays(headDiv);
                var diningEventsByDayPerShift = {};
                for (d in this.props.diningEventsByDay) {
                    var shiftsOnDay = this.props.shiftsByDay[d];
                    diningEventsByDayPerShift[d] = _.reduce(
                      this.props.diningEventsByDay[d],
                      function(expandedPerShift, diningEvent) {
                          var eventsPerShift = [];
                          for (var i = 0; i < diningEvent.shift_categories.length; i++) {
                              var matchingShift = _.find(shiftsOnDay, { category: diningEvent.shift_categories[i] });
                              if (matchingShift) {
                                  // Take the shift start/end times, and all the dining event data on top
                                  var compositeEvent = _.extend({}, matchingShift, diningEvent);
                                  eventsPerShift.push(compositeEvent);
                                  expandedPerShift.push(compositeEvent);
                              }
                          }
                          _.map(eventsPerShift, function(event) { event.num_shifts = eventsPerShift.length; });
                          return expandedPerShift;
                      },
                      []
                    ) || [];
                }
                helpers.applyOverlays(headDiv, diningEventsByDayPerShift);
                helpers.applyOverlays(headDiv, this.props.shiftsByDay, true);
            },

            postDataUpdate: function (diningEventsByDay, date, shiftsByDay) {
                this.props.diningEventsByDay = diningEventsByDay;
                this.props.days = _.keys(this.props.diningEventsByDay);
                this.props.shiftsByDay = shiftsByDay;
                this.setState({selectedDate: date});
            },

            onDateUpdate: function (date) {
                this.props.fetchData(date, this.postDataUpdate);
            },

            componentDidMount: function () {
                this.postRenderHook($(this.getDOMNode()));
                helpers.bindCalendarBehavior(
                    $(this.getDOMNode()).find('.date-selector'),
                    this.state.selectedDate,
                    this.onDateUpdate
                );
            },

            componentDidUpdate: function () {
                this.postRenderHook($(this.getDOMNode()));
            },

            render: function () {
                var tableCss = {
                        width: '98%',
                        marginTop: '1%',
                        marginRight: '3%',
                        borderCollapse: 'separate'
                    },
                    rows = _.map(this.props.intervals, function (x, k) {
                        var is_last = (k == _.size(this.props.intervals) - 1);
                        return <helpers.RowView
                            start_interval={_.first(x)}
                            end_interval={_.last(x)}
                            onClickOverlay={this.props.onClickOverlay}
                            key={k}
                            is_last={is_last}
                            days={this.props.days}
                            className='cell'
                            intervals={this.props.intervals}
                            />;
                    }, this),
                    tHeader = (<helpers.HeaderView
                        days={this.props.days}
                        media_url={this.props.media_url}
                        onDateUpdate={this.onDateUpdate}
                        />);

                return (
                    <div>
                        <helpers.controlView
                            onDateUpdate={this.onDateUpdate}
                            selectedDate={this.state.selectedDate}
                            />
                        <table className='table' style={tableCss}>
                            {tHeader}
                            {rows}
                        </table>
                    </div>
                );
            }
        }),

        applyOverlays: function (headDiv, intervalOverlays, isBackgroundOverlay) {
            for (d in intervalOverlays) {
                if (_.isEmpty(intervalOverlays[d])) {
                    continue;
                }
                var currentIntervals = intervalOverlays[d],
                    all_overlaps = [],
                    current_overlaps = [],
                    prevParentInterval = undefined;
                currentIntervals.sort(function (a, b) {
                    var firstStartDate = new Date(d + " " + a.start_time_display),
                        firstEndDate = new Date(d + " " + a.end_time_display),
                        firstNumShifts = a.hasOwnProperty('num_shifts') ? a.num_shifts : 1,
                        secondStartDate = new Date(d + " " + b.start_time_display),
                        secondEndDate = new Date(d + " " + b.end_time_display),
                        secondNumShifts = b.hasOwnProperty('num_shifts') ? b.num_shifts : 1;
                    a.start_date_time = firstStartDate;
                    a.end_date_time = firstEndDate;
                    b.start_date_time = secondStartDate;
                    b.end_date_time = secondEndDate;
                    return (firstStartDate > secondStartDate ? 1 :
                            firstStartDate < secondStartDate ? -1 :
                            a.num_shifts < b.num_shifts ? 1 :
                            ((firstEndDate - firstStartDate) - (secondEndDate - secondStartDate)))
                });
                for (var i = 0; i < currentIntervals.length; i++) {
                    var currentInterval = currentIntervals[i],
                        prevParentInterval = prevParentInterval ? prevParentInterval : currentInterval,
                        doesOverlap = prevParentInterval &&
                            (!(currentInterval.start_date_time - prevParentInterval.start_date_time) ||
                            (currentInterval.start_date_time < prevParentInterval.end_date_time));
                    if (doesOverlap) {
                        current_overlaps.push(currentInterval);
                    } else {
                        if (current_overlaps.length) {
                            all_overlaps.push(current_overlaps);
                            current_overlaps = [];
                        }
                        current_overlaps.push(currentInterval);
                        prevParentInterval = currentInterval;
                    }
                }
                if (current_overlaps.length) {
                    all_overlaps.push(current_overlaps);
                }

                for (var i = 0; i < all_overlaps.length; i++) {
                    var current_overlaps = all_overlaps[i],
                        hasOverlappingIntervals = current_overlaps.length > 1,
                        totalOverlappingIntervals = current_overlaps.length + 1,
                        maxWidth = hasOverlappingIntervals ? (90 / totalOverlappingIntervals) * 2 : 90;
                    current_overlaps.map(function (s, key) {
                        var start_time = s['start_time_display'],
                            end_time = s['end_time_display'],
                            category = s['category'],
                            start_time_parts = modules.weekViewManager.getHourFraction(start_time),
                            end_time_parts = modules.weekViewManager.getHourFraction(end_time),
                            title = s['title'] || s['name'] || s['category'],
                            id = s['id'],
                            intervalHours = (end_time_parts.hour - start_time_parts.hour) +
                                (end_time_parts.fraction - start_time_parts.fraction) / 4,
                            left = (key == 0) ? 0 : (key * 90 / totalOverlappingIntervals);

                        intervalHours = intervalHours < 0 ? intervalHours + 24 : intervalHours;
                        var tdSelector = [
                            "[data-day='", d, "']",
                            "[data-start='", start_time_parts.hour, "']",
                            "[data-fraction='", start_time_parts.fraction, "']"
                        ].join('');
                        if (isBackgroundOverlay) {
                            helpers.createBackgroundOverlay($(headDiv).find(tdSelector),
                                intervalHours, title, category, id, key, key == 0 ? 80 : maxWidth, left, s['is_override']);
                        } else {
                            helpers.createOverlay($(headDiv).find(tdSelector),
                                intervalHours, title, category, id, key, key == 0 ? 80 : maxWidth, left, s['is_override']);
                        }
                    });
                }
            }
        }
        ,

        generateIntervals: function (start_of_day_hour) {
            return _.times(24, function (n) {
                return [(n + start_of_day_hour) % 24, (n + start_of_day_hour+1) % 24];
            })
        },

        generateDefaultDays: function (date) {
            var d = new Date(date);
            d.addDays(-d.getDay() - 1);
            return _.times(7, function (i) {
                return d.addDays(1).toLocaleDateString().replace(/\u200E/g, '');
                //Work around for MSIE (https://connect.microsoft.com/IE/feedback/details/837517/javascript-date-object-method-tolocaledatestring-and-unicode-character-u200e)
            });
        }
        ,

        generateGrid: function (id, venue_url_key_or_id, media_url, date, HeaderCellMenu,
                                onClickOverlay, fetchData) {
            helpers.id = id;
            helpers.venue_url_key_or_id = venue_url_key_or_id;
            helpers.media_url = media_url;
            helpers.headerCellMenu = HeaderCellMenu;
            helpers.colorsLookup = {};
            React.render(
                <helpers.TableView
                    selectedDate={date}
                    onClickOverlay={onClickOverlay}
                    fetchData={fetchData}
                    />,
                document.getElementById(id)
            );
        }
    };
    var instantiated, media_url, venue_url_key_or_id, id, events, shifts, cellSize = 6;

    var onClickOverlay = function (target) {
        var currentTarget = $(target),
            eventId = $(currentTarget).data('id'),
            selectedEvent = _.findWhere(_.flatten(_.values(events)), {'id': eventId}),
            event = _.extend({}, selectedEvent, {
                selectedDay: $(currentTarget).data('day'),
                selectedDate: new Date($(currentTarget).data('day')),
                category: $(currentTarget).data('category'),
                id: $(currentTarget).data('id')
            })

        $.when(
            event.id ? $.ajax({
                url: '/api-yoa/dining_event/' + event.id + '?venue=' + venue_url_key_or_id
            }) : $.none
        ).then(function (responseBody) {
                var event_data = responseBody ? responseBody['data'] : undefined;
                if (responseBody && responseBody.status != '200') {
                    UserNotificationInterop.error(responseBody.msg);
                } else {
                    if (event.id) {
                        modules.diningeventslideout.view(
                            'flyout-form',
                            venue_url_key_or_id,
                            media_url,
                            event.selectedDay,
                            _.partial(helpers.onAddOverlay, event.id),
                            _.partial(onCloseSlideout, id),
                            event_data
                        );
                    } else {
                        modules.diningeventslideout.add(
                            'flyout-form',
                            venue_url_key_or_id,
                            media_url,
                            event.title,
                            [ event.category ],
                            event.selectedDay,
                            _.partial(helpers.onAddOverlay, id),
                            _.partial(onCloseSlideout, id)
                        );
                    }
                }
            });
    };

    var fetchData = function (date, onFetchData) {
        $.when(
            $.ajax({
                url: '/api-yoa/dining_event',
                data: {
                    venue: venue_url_key_or_id,
                    week_of: date
                }
            }),
            $.ajax({
                url: '/api-yoa/shifts/schedule',
                data: {
                    venue: venue_url_key_or_id,
                    week_of: date
                }
            })
        ).then(function (response1, response2) {
                response1 = _.first(response1);
                response2 = _.first(response2);
                if (response1.status != '200') {
                    UserNotificationInterop.error(response1.msg);
                } else {
                    var diningEvents = _.object(_.map(response1.data.events, function (v, d) {
                        var date = new Date(d),
                            reformatted_date = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
                        return [reformatted_date, v];
                    }));

                    shifts = _.object(_.map(response2.data.shifts, function (v, d) {
                        var date = new Date(d),
                            reformatted_date = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
                        return [reformatted_date, v];
                    }));
                    events = diningEvents;
                    onFetchData(diningEvents, date, shifts);
                }
            }
        );
    };

    var resetStateOfAllOverlays = function (id) {
        $('#' + id).find('.overlay_interval').each(function (index, element) {
            var originalColor = $(element).data('backgroundcolor'),
                originalZIndex = $(element).data('zindex');
            $(element).css({backgroundColor: originalColor, zIndex: originalZIndex});
        });
    };

    var onCloseSlideout = function (id) {
        $('#' + id).find('.ui-resizable').remove();
        resetStateOfAllOverlays(id);
    };

    var HeaderCellMenu = React.createClass({
        onClickHandler: function () {
            var displayElement = $(this.getDOMNode()).find('.utildrop');
            if (displayElement.css('display') == 'none') {
                displayElement.css('display', 'block');
            } else {
                displayElement.css('display', 'none');
            }
        },

        render: function () {
            var isPastDate = new Date(this.props.day) < Pmp.Manager.Global._venue_today_date;
            return (
                <th key={this.props.key} style={{border: 0}}>
                    <span data-day={this.props.day}
                          style={{fontSize: 10, color: isPastDate? 'grey': 'black'}}>
                        {this.props.disp}
                    </span>
                    <span className="dropdown-arrow"
                          onClick={this.onClickHandler}
                          style={{cursor: 'pointer', padding: '0px 0px 0px 10px'}}>
                    </span>
                </th>
            );
        }
    });

    var renderInstance = function (date) {
        if (!instantiated) {
            console.debug('not instantiated');
            return;
        }
        helpers.generateGrid(
            id,
            venue_url_key_or_id,
            media_url,
            date,
            HeaderCellMenu,
            onClickOverlay,
            fetchData
        );
    };

    var refreshInstance = function (date) {
        // HACK: refresh via calendar - since we can't change state from outside
        $('#' + id).find('.date-selector').data('datepicker').settings.onSelect(date);
    };

    var init = function (domId, _venue_url_key_or_id, _media_url, _start_of_day_hour) {
        instantiated = true;
        venue_url_key_or_id = _venue_url_key_or_id;
        media_url = _media_url;
        id = domId;
        start_of_day_hour = Number(_start_of_day_hour);
        renderInstance(null);
    };

    return {
        render: renderInstance,
        init: init,
        resetResizableOverlays: helpers.resetResizableOverlays,
        refresh: refreshInstance
    };
}();
